import Image from 'next/image';

import type { Theme } from 'app/providers/theme-provider';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import styles from './app-store-btn.module.scss';

interface IAppStoreBtnProps {
  theme: Theme;
  className?: string;
  height?: number;
  width?: number;
}

const acceptLocales = new Set([
  'ar',
  'de',
  'en',
  'es',
  'fr',
  'ja',
  'ko',
  'pa',
  'pl',
  'pt',
  'ru',
  'tr',
  'zh',
]);

const AppStoreBtn: React.FC<IAppStoreBtnProps> = ({
  theme,
  className,
  height = 0,
  width = 0,
}) => {
  const { i18n } = useTranslation('common');
  const locale = i18n.language;
  const curLocale = acceptLocales.has(locale) ? locale : 'en';

  return (
    <a
      className={clsx(styles.link, className)}
      href='https://apps.apple.com/ca/app/cryptomus/id6464404665'
      rel='noreferrer'
      target='_blank'
    >
      <Image
        alt='Download on AppStore'
        className={styles.image}
        height={height}
        loading='lazy'
        width={width}
        src={`/icons/app-store-btn/icon-app-${curLocale}-${
          theme === 'dark' ? 'light' : 'dark'
        }.svg`}
      />
    </a>
  );
};

export default AppStoreBtn;
