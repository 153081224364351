'use client';

import Image from 'next/image';

import type { Theme } from 'app/providers/theme-provider';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import styles from './google-play-btn.module.scss';

interface IGooglePlayBtnProps {
  theme: Theme;
  className?: string;
  height?: number;
  locale?: string;
  width?: number;
}

const acceptLocales = new Set([
  'de',
  'en',
  'es',
  'fr',
  'uk',
  'ru',
  'tr',
  'zh',
  'uz',
  'kk',
  'pl',
  'ar',
  'ko',
  'ja',
  'pt',
  'fa',
  'pa',
]);

const GooglePlayBtn: React.FC<IGooglePlayBtnProps> = ({
  theme,
  className,
  height = 56,
  width = 191,
}) => {
  const { i18n } = useTranslation('common');
  const locale = i18n.language;
  const curLocale = acceptLocales.has(locale) ? locale : 'en';

  return (
    <a
      className={clsx(styles.link, className)}
      href={`https://play.google.com/store/apps/details?id=com.cryptomus.bundle&hl=${curLocale}`}
      rel='noreferrer'
      target='_blank'
    >
      <Image
        alt='Download on Google play'
        className={styles.image}
        height={height}
        loading='lazy'
        width={width}
        src={`/icons/google-play-btn/icon-google-${curLocale}-${
          theme === 'dark' ? 'light' : 'dark'
        }.svg`}
      />
    </a>
  );
};

export default GooglePlayBtn;
